


































































































































































































import axios, { CancelTokenSource } from 'axios'
import { isEqual, sortBy } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import LinkPrimary from '@/components/_uikit/links/LinkPrimary.vue'
import OrderInfoModal from '@/components/modals/orders/OrderInfoModal.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
import ManagerOrdersModule from '@/store/modules/manager/orders'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import {
  IOrdersFilter,
  IOrdersFilterType,
  ManagerOrderShortResource,
  NameValueChildrenResource,
  NameValueResource,
  PackageType,
} from '@/store/types'
import { ISearchPackagesFilter } from '@/store/types/tables'
// utils
import { getMonthsRangeForPackages } from '@/utils/functions'
import { tableFooterOptions } from '@/utils/constants'
import AuthModule from '@/store/modules/auth'
import { convertDateToMSK } from '@/utils/functions'

@Component({
  components: {
    Autocomplete,
    CourseSearch,
    DateInput,
    LinkPrimary,
    OrderInfoModal,
    Parameters,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class OrdersTable extends Mixins(NotifyMixin, PermissionsMixin, CourseGroupMonthMixin) {
  @Prop({ default: false })
  private isManager!: boolean

  @Prop({ default: () => ([]) })
  private menu!: NameValueResource[]

  private innerOrders: ManagerOrderShortResource[] = []
  private showOrderInfo = false
  private chosenOrderId = 0
  private allFiltersShow = false

  private footerOptions = tableFooterOptions

  private get filter () {
    return ManagerOrdersModule.ordersFilter
  }

  private set filter (filter: IOrdersFilter) {
    if (!isEqual(filter, this.filter) || this.orders.length === 0) {
      ManagerOrdersModule.setOrdersFilter(filter)
      ManagerCoursesModule.searchPackages(this.packagesSearchOptions as ISearchPackagesFilter)
      this.fetchOrders()
    }
  }

  private get hasPackages() {
    return !!this.filter.packageIds?.length
  }

  private get groupTypes () {
    return DictionaryModule.groupTypes
  }

  private get orderStatuses () {
    return DictionaryModule.orderStatuses
  }

  private get discounts() {
    return DictionaryModule.discounts
  }

  private get cashboxes() {
    return DictionaryModule.cashboxes
  }

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Пользователь', value: 'user' },
      { text: 'Заказ', value: 'order' },
      { cellClass: 'cell-width-12', text: 'Стоимость, ₽', value: 'price' },
      { cellClass: 'cell-width-13', text: 'Дата и время создания', value: 'createdAt' },
      { cellClass: 'cell-width-13', text: 'Дата и время оплаты', value: 'paidAt' },
      { text: 'Статус', value: 'status' },
    ]
  }

  private get orders () {
    return ManagerOrdersModule.orders.data
  }

  private get pagination () {
    return ManagerOrdersModule.orders.meta
  }

  private get packages() {
    const result = []
    for(let key in ManagerCoursesModule.searchedPackages) {
      let item = {
        name: `${ManagerCoursesModule.searchedPackages[key].groupType.name} [${getMonthsRangeForPackages(ManagerCoursesModule.searchedPackages[key].months)}]`,
        value: ManagerCoursesModule.searchedPackages[key].id,
      }
      result.push(item)
    }
    return result
  }

  private get packagesSearchOptions() {
    return {
      courseId: this.filter.courseId ?? null,
      packageType: PackageType.STARTER,
    }
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private filterDateCreatedAt: string[] = this.filter.createdStartAt && this.filter.createdEndAt ? [this.filter.createdStartAt, this.filter.createdEndAt] : []
  private filterDatePaidAt: string[] = this.filter.paidStartAt && this.filter.paidEndAt ? [this.filter.paidStartAt, this.filter.paidEndAt] : []
  private totals: NameValueResource[] = []

  private isLoading = false
  private source: CancelTokenSource | null = null

  private mounted() {
    ManagerCoursesModule.searchPackages(this.packagesSearchOptions as ISearchPackagesFilter)
    DictionaryModule.fetchDiscounts()
      .then(() => {
        this.innerOrders = this.orders
      })
      .catch(this.notifyError)
  }

  private fetchOrderPrices() {
    ManagerOrdersModule.fetchOrderPrices({
      courseId: this.filter.courseId,
      createdEndAt: this.filter.createdEndAt,
      createdStartAt: this.filter.createdStartAt,
      groupType: this.filter.groupType,
      paidEndAt: this.filter.paidEndAt,
      paidStartAt: this.filter.paidStartAt,
      saleIds: this.filter.saleIds,
      self: this.filter.self,
      status: this.filter.status,
    })
      .then(response => {
        const res: NameValueResource[] = sortBy(response, 'value')
        // Нужно перевести значения в строки, потому что при отправке числа 0 фильтр цены сбрасывается
        this.totals = res.map(item => ({ ...item, value: item.value.toString() }))
        if (!this.totals.find(price => price.value.toString() === this.filter.price?.toString())) {
          this.filter.price = undefined
        }
      })
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(300)
  private fetchOrders () {
    const CancelToken = axios.CancelToken
    if (this.isLoading && this.source) {
      this.source.cancel()
    }
    this.source = CancelToken.source()
    this.isLoading = true
    ManagerOrdersModule.fetchOrders({ cancelToken: this.source.token })
      .then(response => {
        this.innerOrders = response.data
        this.isLoading = false
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.notifyError(err)
          this.isLoading = false
        }
      })
  }

  private handleFilter (field: IOrdersFilterType, value: any) {
    if (field === 'courseId') {
      this.filter = {
        ...this.filter,
        [field]: value,
        page: 1,
        packageIds: undefined,
        price: undefined,
      }
    } else if (field === 'createdAt') {
      if (value.length) {
        this.filter = {
          ...this.filter,
          createdEndAt: convertDateToMSK(`${value[1]} 23:59:59`, this.isLocalTimezone),
          createdStartAt: convertDateToMSK(`${value[0]} 00:00:00`, this.isLocalTimezone),
          page: 1,
        }
      } else {
        this.filter = {
          ...this.filter,
          createdEndAt: undefined,
          createdStartAt: undefined,
          page: 1,
        }
      }
    } else if (field === 'paidAt') {
      if (value.length) {
        this.filter = {
          ...this.filter,
          page: 1,
          paidEndAt: convertDateToMSK(`${value[1]} 23:59:59`, this.isLocalTimezone),
          paidStartAt: convertDateToMSK(`${value[0]} 00:00:00`, this.isLocalTimezone),
        }
      } else {
        this.filter = {
          ...this.filter,
          page: 1,
          paidEndAt: undefined,
          paidStartAt: undefined,
        }
      }
    } else {
      this.filter = {
        ...this.filter,
        [field]: value,
        page: 1,
      }
    }
    if (field !== 'price') {
      if (this.filter.courseId && value) {
        this.fetchOrderPrices()
      }
    }
  }

  private toggleFilterView() {
    this.allFiltersShow = !this.allFiltersShow
  }

  private handleUpdateList(list: NameValueChildrenResource[]) {
    if (list.length === 1)
      ManagerOrdersModule.setTotalOptions(list)
    else if (list.length > 1) {
      const findCourse = list.find(course => course.value === this.filter.courseId)
      if (findCourse)
        ManagerOrdersModule.setTotalOptions([findCourse])
    }
  }

  private handleUpdateOrder(order: { orderId: number, orderStatus: NameValueResource }) {
    this.innerOrders = this.innerOrders.map(item => {
      if (item.id === order.orderId) {
        return { ...item, status: order.orderStatus }
      }
      return item
    })
  }

  private handleRowClick (order: ManagerOrderShortResource) {
    this.chosenOrderId = order.id
    this.showOrderInfo = true
  }
}
